<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <p class="h3">
              Update User Password
            </p>
            <div>
              <form @submit.prevent="UpdatePassword">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="email"
                    required
                    class="form-control"
                    placeholder="Enter users email"
                  />
                </div>
                <div class="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="password1"
                    required
                    aria-describedby="emailHelp"
                    placeholder="Enter Password"
                  />
                </div>
                <div class="form-group">
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    class="form-control"
                    v-model="password2"
                    required
                    aria-describedby="emailHelp"
                    placeholder="Enter Password"
                  />
                </div>
                <div class="form-group">
                  <label>Type Reason</label>
                  <textarea required class="form-control" rows="3"></textarea>
                </div>

                <button
                  v-if="!loadingUpdate"
                  type="submit"
                  class="btnd btn-success text-light"
                >
                  Update
                </button>
                <button
                  v-else
                  class="btnd btn-success text-light"
                  type="button"
                  disabled
                >
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Loading...
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UpdatePassword",
  data() {
    return {
      loadingUpdate: false,
      password1: "",
      password2: "",
    };
  },

  methods: {
    UpdatePassword() {
      if (this.password1 != this.password2) {
        this.$toast.open({
          message: "Password does not match",
          position: "top-right",
          type: "error",
          duration: 2000,
          pauseOnHover: true,
        });
      } else {
        this.loadingUpdate = true;
        setTimeout(() => {
          this.$toast.open({
            message: "User's Password updated successfully",
            position: "top-right",
            type: "success",
            duration: 2000,
            pauseOnHover: true,
          });
          this.loadingUpdate = false;
        }, 600);
      }
    },
  },
};
</script>

<style scoped></style>
